import React from "react"

import Modal from "@app/components/Modal"

import { formatDateInTimezone } from "@app/utils"
import { DateFormatter } from "@app/utils/constants"
import Button from "@app/components/Button"

type OverlapJob = JobType & { edate: string }

type PropsType = {
  show: boolean
  onHide: () => void
  overlappingShiftData: {
    [roleName: string]: OverlapJob[]
  }
  edate: string
}

export default ({ show, onHide, overlappingShiftData, edate }: PropsType) => {
  const getTimeString = (jobs: OverlapJob[]) =>
    jobs.map((job) => {
      if (job.edate !== edate) {
        return (
          <li>{`${job.name} ${formatDateInTimezone(
            new Date(job.starttime),
            "utc",
            DateFormatter.shortHourMinute12h
          )} - ${formatDateInTimezone(
            new Date(job.endtime),
            "utc",
            DateFormatter.shortHourMinute12h
          )} (${formatDateInTimezone(
            job.edate,
            "utc",
            DateFormatter.longLocalized
          )})`}</li>
        )
      } else {
        return (
          <li>{`${job.name} ${formatDateInTimezone(
            new Date(job.starttime),
            "utc",
            DateFormatter.shortHourMinute12h
          )} - ${formatDateInTimezone(
            new Date(job.endtime),
            "utc",
            DateFormatter.shortHourMinute12h
          )}`}</li>
        )
      }
    })
  return (
    <Modal
      onHide={onHide}
      show={show}
      noFooter={true}
      title="Overlapping shift error"
      titleAlign="center"
      closeButton={false}
    >
      <p>
        You are attempting to schedule jobs with overlapping times that are
        mapped to the same role in TigerConnect.
      </p>
      <p>
        In order to proceed with saving these assignments, please fix the
        following conflicts.
      </p>
      <ul>
        {Object.keys(overlappingShiftData).map((roleName) => (
          <li>
            {roleName} is being populated by the following conflicting jobs.
            <ul>{getTimeString(overlappingShiftData[roleName])}</ul>
          </li>
        ))}
      </ul>
      <div className="d-flex justify-content-center">
        <Button onClick={onHide}>Close</Button>
      </div>
    </Modal>
  )
}
