import React, { Fragment, useEffect, useState } from "react"
import { useDispatch, useSelector } from "@app/models"
import { useIsAuthenticated, useClinicPermission } from "@app/hooks"
import { Container } from "@app/components/Layout"
import Menu from "@app/containers/layouts/Menu"
import Footer from "@app/containers/layouts/Footer"
import Loader from "@app/components/Loader"
import TrainerReports from "../../../admin-dashboard/trainer-reports/TrainerReports"
import css from "./EnterpriseReport.module.scss"

const EnterpriseReport: React.FC<any> = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true)
  const { currentUser } = useSelector((state) => state.users)

  const dispatch = useDispatch()

  useEffect(() => {
    ;(async () => {
      await dispatch.users.getCurrentUserInfo()
    })()
  }, [])

  const isAuthenticated = useIsAuthenticated()
  const canAccessEnterpriseReporting = useClinicPermission("Reporting")

  useEffect(() => {
    if (canAccessEnterpriseReporting === null) return

    if (isAuthenticated) {
      if (!canAccessEnterpriseReporting) {
        window.location.href = "/menu.cgi"
      } else {
        setIsLoading(false)
      }
    }
  }, [isAuthenticated, canAccessEnterpriseReporting])

  return (
    <Fragment>
      <Menu userInfo={currentUser} />
      <Container size="fluid" className={css.container}>
        {isLoading ? (
          <div className={css.loadingContainer}>
            <Loader animation="dots" />
          </div>
        ) : (
          <div id="content">
            <TrainerReports reportsAdmin={true} />
          </div>
        )}
        <Footer />
      </Container>
    </Fragment>
  )
}

export default EnterpriseReport
