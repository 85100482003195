import React, { Fragment, useEffect, useMemo, useState } from "react"
import css from "./AssignmentContentModal.module.scss"
import Form from "react-bootstrap/Form"
import { Col } from "react-bootstrap"
import { FormItem, useForm } from "@app/components/Form"
import IconButton from "@material-ui/core/IconButton"
import ClearIcon from "@material-ui/icons/Clear"
import { useCaniuseFeature } from "@app/utils/hooks"
import { formatDate, handleApiError } from "@app/utils"
import Modal, { Dialog } from "@app/components/Modal"
import { DateFormatter } from "@app/utils/constants"
import ProvidersCustomSelectComponent from "../ProviderCustomSelect/ProvidersCustomSelect"
import { Flag } from "@material-ui/icons"
import ToggleSwitch from "@app/components/Toggle/Toggle"
import { useSelector } from "@app/models"
import { useFieldArray, Controller } from "@app/components/Form"
import {
  createSingleAssignment,
  updateAssignment,
} from "@app/services/singleAssignment"
import useRequest from "@app/utils/request"
import api, { mutate } from "@app/services/api"
import { SplitShift, DraftToPublish } from "../../data"
import SplitShiftView from "./SplitShiftView"
import { validateSplitShifts } from "@app/services/splitShiftValidation"
import AvailableProviders from "../AvailableProviders/AvailableProviders"
import Button from "@app/components/Button"
import { format, parseISO } from "date-fns"
import DraftPublish from "../DraftPublish"
import {
  optimisticDeleteEvent,
  optimisticUpdateEvents,
} from "@app/containers/spa/WhiteboardCalendar/utils/calendarUpdates"
import { formatDateToStartEnd } from "@app/utils"
import OverlappingShiftError from "../OverlappingShiftError"

type Props = {
  title?: string | React.ReactNode
  content?: string | React.ReactNode
  className?: string
  show: boolean
  selectedAssignment: AssignmentBaseType
  jobid: number
  edate: string
  hideModal: () => void
}

type FormFieldsType = {
  providerids: { providerid: number }[]
}

const AssignmentContentModal = ({
  show,
  hideModal,
  selectedAssignment,
  jobid,
  edate,
}: Props) => {
  const [assignment, setAssignment] = useState<AssignmentBaseType>()
  const [selectedSplitShifts, setSelectedSplitShifts] = useState<SplitShift[]>(
    []
  )
  const [splitShifts, setSplitShifts] = useState<SplitShift[]>([])
  const [isSplitShiftView, setIsSplitShiftView] = useState(false)
  const [showProviders, setShowProviders] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [draftToPublish, setDraftToPublish] = useState<
    DraftToPublish | undefined
  >(undefined)
  const [showOverlappingShiftModal, setShowOverlappingShiftModal] =
    useState(false)
  const [overlappingShiftData, setOverlappingShiftData] = useState({})

  const { assignments, draftEvents, flags } = useSelector(
    (state) => state.calendarEvents.events
  )

  const {
    filterOptions: { startDate, endDate },
    rulesConfig: { draft_mode_scheduling },
  } = useSelector((state) => state.calendarEvents.calendarConfig)

  const { jobs } = useSelector((state) => state.groupData)

  const splitShiftEnabled = useCaniuseFeature("split_shift", {
    scope: "group",
  })

  const draftModeEnabled = useCaniuseFeature("draft_mode", {
    scope: "group",
  })

  const getPreviousAssignment = useMemo(() => {
    if (selectedAssignment.providerid) return selectedAssignment
    return [...assignments, ...draftEvents].find(
      (event) => event.jobid === jobid && event.edate === edate
    )
  }, [edate, selectedAssignment])

  const initToggleDraftState = (): boolean => {
    if (!draftModeEnabled) return false

    const assignment = getPreviousAssignment
    return assignment
      ? Boolean(assignment.draft_eventid)
      : draft_mode_scheduling
  }

  const [draftMode, setDraftMode] = useState<boolean>(initToggleDraftState())

  const formattedDate = useMemo(
    () => (edate ? format(parseISO(edate), "EEEE do MMMM yyyy") : ""),
    [edate]
  )

  const { data: jobSettings } = useRequest([
    api.getJobMultipleAssignmentSettings,
    jobid,
    edate,
  ])

  const {
    control,
    handleModalSubmit,
    reset,
    watch,
    formState: { isDirty },
  } = useForm<FormFieldsType>({
    defaultValues: {
      providerids: [{ providerid: 0 }],
    },
    schema: (yup) =>
      yup.lazy(() =>
        yup.object().shape({
          providerids: yup.array().of(
            yup.object().shape({
              providerid: yup.number().required(),
            })
          ),
        })
      ),
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: "providerids",
  })

  const rows = watch("providerids")

  const isSplitViewDirty = useMemo(
    () => JSON.stringify(splitShifts) !== JSON.stringify(selectedSplitShifts),
    [splitShifts, selectedSplitShifts]
  )

  const currentJob = jobs.find((job) => job.jobid === jobid)

  if (!currentJob) return null

  const splitShiftAvailable =
    splitShiftEnabled && !currentJob.multiple_assignments

  const currentDayid = new Date(`${edate}T00:00:00`).getDay()
  const jobDaytype = currentJob.job_day_types?.find(
    (jdt: any) => jdt.dayid === currentDayid + 1
  )
  const jobStarttime = jobDaytype?.starttime || currentJob.starttime
  const jobEndtime = jobDaytype?.endtime || currentJob.endtime

  const findFlag = () =>
    flags.find(
      (flag) => flag.link_date.link_date === edate && flag.jobid === jobid
    )

  const submitSplitShifts = () => {
    const splitShiftsFiltered = selectedSplitShifts
      .filter((splitShift) => !splitShift.disabled)
      .map(({ disabled, ...splitShift }) => splitShift)

    if (splitShiftsFiltered && currentJob) {
      const splitShiftValidation = validateSplitShifts(
        splitShiftsFiltered,
        currentJob,
        new Date(`${edate}T00:00:00`)
      )
      if (splitShiftValidation) {
        Dialog.warn({ title: "Error", message: splitShiftValidation })
        return false
      } else {
        api
          .updateSplitShifts(edate, jobid, splitShiftsFiltered, "update")
          .then((splitShifts) => {
            optimisticUpdateEvents(splitShifts, startDate, endDate)
            hideModal()
          }, handleApiError)
      }
    }
    return true
  }

  const onHideUpsertModal = () => {
    setIsLoading(false)
    hideModal()
  }

  const submitForm = (fields: FormFieldsType) => {
    setIsLoading(true)
    try {
      const previousAssignment = getPreviousAssignment
      const flag = findFlag()
      const providerids = fields.providerids.map(
        (provider) => provider.providerid
      )

      if (previousAssignment) {
        updateAssignment(providerids, previousAssignment).then((events) => {
          optimisticUpdateEvents(events, startDate, endDate)
          onHideUpsertModal()
        }, handleApiError)
      } else {
        if (flag) {
          createSingleAssignment(providerids, jobid, edate, draftMode)
            .then((events) => {
              api.deleteFlag(flag.link_date.link_date, flag.jobid).then(() => {
                optimisticUpdateEvents(events, startDate, endDate)
                onHideUpsertModal()
              }, handleApiError)
            })
            .catch((err) => {
              if (err.data && err.data.title === "Overlapping shift error") {
                setOverlappingShiftData(err.data.data)
                setShowOverlappingShiftModal(true)
              } else {
                handleApiError(err)
              }
            })
        } else {
          createSingleAssignment(providerids, jobid, edate, draftMode)
            .then((events) => {
              optimisticUpdateEvents(events, startDate, endDate)
              onHideUpsertModal()
            })
            .catch((err) => {
              if (err.data && err.data.title === "Overlapping shift error") {
                setOverlappingShiftData(err.data.data)
                setShowOverlappingShiftModal(true)
              } else {
                handleApiError(err)
              }
            })
        }
      }
    } catch (error) {
      if (error instanceof Error) {
        handleApiError({
          ...error,
          fail: true,
        } as PlainObjectType)
      }
    }
  }

  const handleDelete = () => {
    const previousAssignment = getPreviousAssignment
    const id = previousAssignment?.draft_eventid || previousAssignment?.eventid
    const deleteApi = previousAssignment?.draft_eventid
      ? api.deleteDraftEvent
      : api.deleteEvent
    if (id) {
      deleteApi(id).then((res) => {
        hideModal()
        optimisticDeleteEvent(
          {
            edate: previousAssignment.edate,
            jobid: previousAssignment.jobid,
          },
          startDate,
          endDate,
          Boolean(previousAssignment.draft_eventid)
        )
      }, handleApiError)
    }
  }

  const handlePublishToggle = (value?: boolean) => {
    value
      ? setDraftToPublish({
          jobid: `${currentJob.jobid}`,
          abbrev: currentJob.abbrev,
          multiple_assignments: false,
        })
      : setDraftToPublish(undefined)

    setDraftMode(!value)
  }

  const handleToggleFlag = () => {
    const flag = findFlag()
    const previousAssignment = getPreviousAssignment

    if (!previousAssignment) {
      if (flag) {
        api.deleteFlag(flag.link_date.link_date, flag.jobid).then(() => {
          mutate([api.getFlags, startDate, endDate])
          hideModal()
        }, handleApiError)
      } else {
        api.createFlag(edate, jobid).then(() => {
          mutate([api.getFlags, startDate, endDate])
          hideModal()
        }, handleApiError)
      }
    }
  }

  const showAvailableProviders = () => {
    setShowProviders(true)
  }

  const handleBack = () => {
    setShowProviders(false)
  }

  const isSaveButtonDisabled = useMemo(
    () =>
      isLoading ||
      (!isDirty && !isSplitShiftView) ||
      (isSplitShiftView && !isSplitViewDirty),

    [isLoading, isDirty, isSplitShiftView, isSplitViewDirty]
  )

  const renderToggleSwitch = () => (
    <div className={css.toggleSwitchContainer}>
      <ToggleSwitch
        key={`${draftMode}`}
        value={!draftMode}
        onToggle={handlePublishToggle}
        disabled={!draftModeEnabled || Boolean(getPreviousAssignment?.eventid)}
      />
      <span className={css.toggleSwitchLabel}>
        {draftMode ? "Unpublished" : "Published"}
      </span>
    </div>
  )

  const modalTitle = (
    <>
      <div className={css.modalAssigmentTitle}>
        <div className={css.titleContent}>
          <div className={css.headerContainer}>
            <button
              className={css.titleButton}
              onClick={showAvailableProviders}
            >
              <span>{currentJob?.name} </span>
            </button>
            <span>
              <Flag
                style={{ color: findFlag() ? "red" : "grey" }}
                className={css.flagIcon}
                role="button"
                onClick={handleToggleFlag}
              />
            </span>
            <div>
              <span className={css.modalSubtitle}>
                {`${formattedDate}, ${formatDateToStartEnd(
                  jobStarttime,
                  jobEndtime
                )}`}
              </span>
            </div>
          </div>

          {!showProviders &&
            (draftToPublish && assignment ? (
              <DraftPublish
                item={{
                  jobid: `${currentJob!.jobid}`,
                  abbrev: currentJob!.abbrev,
                  multiple_assignments: false,
                }}
                edate={edate}
                showPublish={setDraftToPublish}
                onCancel={handlePublishToggle}
                onSuccess={hideModal}
              />
            ) : (
              renderToggleSwitch()
            ))}
        </div>
        {showProviders && (
          <Button
            variant="primary"
            className={css.customButton}
            onClick={handleBack}
          >
            Back
          </Button>
        )}
      </div>
    </>
  )

  useEffect(() => {
    if (assignments && draftEvents) {
      const previousAssignment = getPreviousAssignment
      setAssignment(previousAssignment)

      if (previousAssignment?.split_shift) {
        setIsSplitShiftView(true)
        setSplitShifts(
          assignments
            .filter(
              (splitShift) =>
                splitShift.jobid === previousAssignment.jobid &&
                splitShift.edate === previousAssignment.edate &&
                splitShift.split_shift
            )
            .map((splitShift: AssignmentBaseType) => {
              return {
                starttime: splitShift.split_shift?.starttime as string,
                endtime: splitShift.split_shift?.endtime as string,
                tally_credit: splitShift.split_shift?.tally_credit as number,
                providerid: splitShift.providerid as number,
                edate: splitShift.split_shift?.edate as string,
                disabled: false,
              }
            })
        )
      }
    }
  }, [assignments, draftEvents, jobid, edate])

  useEffect(() => {
    if (assignment) {
      const mainProviderId = assignment?.providerid
      const additionalEventAssignments = assignment.additional_event_assignments
      const additionalProviderIds =
        additionalEventAssignments?.map(
          (additionalAssignment) => additionalAssignment.providerid
        ) || []

      reset({
        providerids: [
          { providerid: mainProviderId },
          ...additionalProviderIds.map((providerid) => ({ providerid })),
        ],
      })
    }
  }, [assignment])

  return (
    <Modal
      show={show}
      onHide={showProviders ? handleBack : hideModal}
      title={modalTitle}
      closeButton={!showProviders}
      titleAlign="left"
      data={assignment}
      size={isSplitShiftView ? "lg" : "md"}
      className={css.modal}
      buttons={
        showProviders
          ? []
          : [
              {
                text: "Delete",
                position: "right",
                onClick: handleDelete,
                variant: "outlined",
                disabled: !assignment?.providerid,
              },
              {
                text: "Save",
                position: "right",
                variant: "contained",
                disabled: isSaveButtonDisabled,
                onClick: isSplitShiftView
                  ? submitSplitShifts
                  : handleModalSubmit(submitForm),
              },
            ]
      }
    >
      {showProviders ? (
        <AvailableProviders jobId={jobid} edate={edate} />
      ) : (
        <>
          {selectedSplitShifts.length ||
          splitShifts.length ||
          isSplitShiftView ? (
            <SplitShiftView
              jobid={jobid}
              edate={edate}
              setSelectedSplitShifts={setSelectedSplitShifts}
              splitShifts={splitShifts}
              assignment={assignment}
            />
          ) : (
            <div className={css.customPopoverContent}>
              <Form>
                <div className="row">
                  <Form.Group as={Col} className="col-md-6 mb-0">
                    <Form.Label className={css.labelPopup}>
                      Provider(s)
                    </Form.Label>
                  </Form.Group>
                  <Form.Group as={Col} className="col-md-6 mb-0">
                    <Form.Label className={css.labelPopup}>
                      Shift Times
                    </Form.Label>
                  </Form.Group>
                </div>

                {fields.map((row, index) => (
                  <div className="row" key={row.id}>
                    <Form.Group
                      as={Col}
                      controlId={`providerSelect-${index}`}
                      className="col-md-6 mb-0"
                    >
                      <FormItem
                        className={css.formItem}
                        label=""
                        layout={[0, 12]}
                        name={`providerids[${index}].providerid`}
                        control={control}
                      >
                        <Controller
                          name="providerid"
                          key={row.id}
                          render={({ field: { onChange, value } }) => (
                            <ProvidersCustomSelectComponent
                              onChange={onChange}
                              jobid={String(jobid)}
                              edate={edate}
                              defaultProviderId={value}
                              filterProviderIds={rows
                                .map((provider) => provider.providerid)
                                .filter((providerid) => providerid !== 0)}
                            />
                          )}
                        />
                      </FormItem>
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      controlId={`shiftTimes-${index}`}
                      className={
                        isSplitShiftView ? "col-md-3 mb-0" : "col-md-6 mb-0"
                      }
                    >
                      <Fragment>
                        <div className={css.labelTimes}>
                          <span>
                            {formatDate(
                              new Date(`2023-01-01T${jobStarttime}`),
                              DateFormatter.hourMinute12h
                            )}{" "}
                            to{" "}
                            {formatDate(
                              new Date(`2023-01-01T${jobEndtime}`),
                              DateFormatter.hourMinute12h
                            )}
                          </span>

                          {splitShiftAvailable &&
                            rows.length === 1 &&
                            getPreviousAssignment?.eventid && (
                              <a
                                onClick={() => setIsSplitShiftView(true)}
                                className={css.splitLink}
                              >
                                Split
                              </a>
                            )}

                          {rows.length > 1 && (
                            <IconButton
                              aria-label="upload picture"
                              component="span"
                              onClick={() => remove(index)}
                              style={{
                                width: 22,
                                height: 22,
                                padding: 0,
                                marginLeft: 10,
                                backgroundColor: "#FFFFFF",
                                color: "#3080DF",
                                border: "1px solid #CDD9DE",
                                visibility: "visible",
                              }}
                            >
                              {" "}
                              <ClearIcon
                                fontSize="small"
                                style={{ color: "#A2B0BC" }}
                              />
                            </IconButton>
                          )}
                        </div>
                      </Fragment>
                    </Form.Group>
                  </div>
                ))}
                {jobSettings &&
                  rows.length < jobSettings.maximum &&
                  rows[0].providerid !== 0 && (
                    <div className="row">
                      <div className="col-12 text-center my-3">
                        <a
                          onClick={() => append({ providerid: 0 })}
                          className={css.addProviderLink}
                        >
                          + Add Provider
                        </a>
                      </div>
                    </div>
                  )}
              </Form>
              <OverlappingShiftError
                onHide={() => {
                  setIsLoading(false)
                  setShowOverlappingShiftModal(false)
                }}
                show={showOverlappingShiftModal}
                overlappingShiftData={overlappingShiftData}
                edate={edate}
              />
            </div>
          )}
        </>
      )}
    </Modal>
  )
}

export default AssignmentContentModal
