import React, { useEffect, useState, useMemo, useRef, Fragment } from "react"
import { TableVirtuoso } from "react-virtuoso"
import api, { useRequest } from "@app/services/api"
import { useSelector } from "@app/models"
import GroupManagementHeader from "./GroupManagementHeader"
import css from "./GroupManagementTable.module.scss"
import Tooltip from "@app/components/Tooltip"
import Modal from "@app/components/Modal"
import { handleApiError } from "@app/utils"
import { transformToBooleans } from "../utils/transformToBolean"
interface GroupData {
  groupid: number
  name: string
  abbrev: string
  jobs_count: number
  tc_integrated_jobs_count: number
  providers_count: number
  active_providers_count: number
  schedulers_count: number
  active_schedulers_count: number
  upcoming_assignments_count: string
  latest_assigned_date: string
  latest_assigned_date_draft: string
  provider_protocols_count: number
  protocols_count: number
  tigerconnect_roles_sync: boolean
  tigerconnect_roles_sync_2way: boolean

  scheduling_gap_notifications: boolean
  timezone: string
  daylight_savings: boolean
}

interface GroupSettings {
  block_scheduling: boolean
  batch_import_export: boolean
  multicalendar_report: boolean
  split_shift: boolean
  always_track_splitshift: boolean
  casting_jobs: boolean
  draft_mode: boolean
  multiple_assignments: boolean
  calendar_v2: boolean
  tigerconnect_settings: TigerConnectSetting[] | null
  [key: string]: boolean | string | TigerConnectSetting[] | null
}

interface TigerConnectSetting {
  id: string
  groupid: number
  label: string
  organization_token: string
  integrationid: string
  tigerconnect_key: string
  tigerconnect_secret: string
  created_at: string
  updated_at: string
  two_way_sync: boolean
}

interface SettingsTableData {
  label: string
  value: string
  integrationid?: string
  two_way_sync?: boolean
}

const PAGE_SIZE = 50

const settingsMap: Record<
  | "batch_import_export"
  | "multicalendar_report"
  | "split_shift"
  | "always_track_splitshift"
  | "block_scheduling"
  | "casting_jobs"
  | "draft_mode"
  | "multiple_assignments"
  | "calendar_v2",
  string
> = {
  batch_import_export: "Batch Import / Export",
  multicalendar_report: "Multicalendar Report",
  split_shift: "Split Shift",
  always_track_splitshift: "Always Track Split Shift Changes",
  block_scheduling: "Block Scheduling",
  casting_jobs: "Allow display of empty assignments",
  draft_mode: "Draft Mode",
  multiple_assignments: "Allow multiple assignments for one job",
  calendar_v2: "Calendar 2.0 (Enable only for EA clients)",
}

const GroupManagementTable: React.FC = () => {
  const { clinicid } = useSelector((state) => state.users.currentUser)
  const { data: clinicData, mutate: refreshClinicData } = useRequest<{
    groups: GroupData[]
    total: number
    page: number
    per: number
  }>(clinicid ? [api.getGroupsByClinic, clinicid] : null)
  const [searchTerm, setSearchTerm] = useState<string>("")
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [sortBy, setSortBy] = useState<
    "name" | "latest_assigned_date" | "latest_assigned_date_draft" | null
  >(null)
  const [sortOrder, setSortOrder] = useState<"asc" | "desc" | null>(null)

  const virtuosoRef = useRef<any>(null)
  const filteredData = useMemo(() => {
    if (!clinicData) return []
    return clinicData.groups.filter(
      (group) =>
        group.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        group.groupid.toString().includes(searchTerm)
    )
  }, [clinicData, searchTerm])
  const [showDetailModal, setShowDetailModal] = useState<boolean>(false)
  const [selectedGroup, setSelectedGroup] = useState<GroupData | null>(null)
  const [groupSettings, setGroupSettings] = useState<GroupSettings | null>(null)
  const [settingsTableData, setSettingsTableData] = useState<
    SettingsTableData[]
  >([])
  const [editedGroupName, setEditedGroupName] = useState<string | null>(null)
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [editingRowIndex, setEditingRowIndex] = useState<number | null>(null)
  const [isEditingRow, setIsEditingRow] = useState<boolean>(false)
  const [editingRowData, setEditingRowData] = useState<
    Record<number, TigerConnectSetting>
  >({})
  const [originalGroupSettings, setOriginalGroupSettings] =
    useState<GroupSettings | null>(null)

  const sortedData = useMemo(() => {
    if (!sortBy) {
      return filteredData
    }

    return [...filteredData].sort((a, b) => {
      if (sortBy === "name") {
        return sortOrder === "asc"
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name)
      } else {
        return sortOrder === "asc"
          ? new Date(a.latest_assigned_date).getTime() -
              new Date(b.latest_assigned_date).getTime()
          : new Date(b.latest_assigned_date).getTime() -
              new Date(a.latest_assigned_date).getTime()
      }
    })
  }, [filteredData, sortBy, sortOrder])

  const pagedData = useMemo(() => {
    const start = (currentPage - 1) * PAGE_SIZE
    const end = start + PAGE_SIZE
    return sortedData.slice(start, end)
  }, [sortedData, currentPage])

  useEffect(() => {
    setCurrentPage(1)
  }, [searchTerm])

  useEffect(() => {
    if (virtuosoRef.current) {
      virtuosoRef.current.scrollToIndex({ index: 0, align: "start" })
    }
  }, [currentPage])

  const handleSortChange = (
    key: "name" | "latest_assigned_date" | "latest_assigned_date_draft"
  ) => {
    if (sortBy === key) {
      setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"))
    } else {
      setSortBy(key)
      setSortOrder("desc")
    }
  }

  const startIndex = (currentPage - 1) * PAGE_SIZE + 1
  const endIndex = Math.min(currentPage * PAGE_SIZE, filteredData.length)
  const totalItems = filteredData.length
  const formatTimezone = (group: GroupData) => {
    return group.timezone
      ? `${group.timezone}${group.daylight_savings ? " DST" : ""}`
      : ""
  }

  const openGroupDetailModal = async (group: GroupData) => {
    try {
      const groupSettingsResponse = await api.getGroupSettings(group.groupid)
      const transformedSettings = transformToBooleans(groupSettingsResponse)
      const tableData = [
        {
          label: groupSettingsResponse.tigerconnect_organization_name,
          value: `Token: ${
            groupSettingsResponse.tigerconnect_organization || "N/A"
          }, Key: ${
            groupSettingsResponse.tigerconnect_key || "N/A"
          }, Integration ID: ${
            groupSettingsResponse.tigerconnect_integrationid || "N/A"
          }`,
        },
        ...(Array.isArray(groupSettingsResponse.tigerconnect_settings)
          ? groupSettingsResponse.tigerconnect_settings.map(
              (setting: TigerConnectSetting) => ({
                label: setting.label || "Unnamed Setting",
                value: `Token: ${setting.organization_token || "N/A"}, Key: ${
                  setting.tigerconnect_key || "N/A"
                }, Integration ID: ${setting.integrationid || "N/A"}`,
              })
            )
          : []),
      ]
      setSelectedGroup(group)
      setGroupSettings(transformedSettings as GroupSettings)
      setOriginalGroupSettings(transformedSettings as GroupSettings)
      setEditedGroupName(group.name)
      setShowDetailModal(true)
      setSettingsTableData(tableData as { label: string; value: string }[])
    } catch (error) {
      handleApiError(error as PlainObjectType)
    }
  }

  const handleSave = async () => {
    if (!selectedGroup || !groupSettings) return

    const groupId = selectedGroup.groupid
    const changes: Promise<any>[] = []
    setIsSaving(true)

    if (editedGroupName && editedGroupName !== selectedGroup.name) {
      changes.push(
        api
          .updateGroupById(groupId, { name: editedGroupName })
          .catch((error) => handleApiError(error))
      )
    }

    const updatedSettings = Object.keys(settingsMap).reduce((acc, key) => {
      const settingKey = key as keyof GroupSettings
      if (groupSettings[settingKey] !== originalGroupSettings?.[settingKey]) {
        acc[settingKey] = groupSettings[settingKey] ? "1" : "0"
      }
      return acc
    }, {} as Record<string, string>)

    if (Object.keys(updatedSettings).length > 0) {
      changes.push(
        api
          .updateGroupSettings(groupId, updatedSettings)
          .catch((error) => handleApiError(error))
      )
    }

    Object.entries(editingRowData).forEach(([index, rowData]) => {
      const idx = parseInt(index, 10)
      if (
        idx === 0 &&
        (rowData.two_way_sync !== groupSettings?.tigerconnect_2way_sync ||
          rowData.integrationid !== groupSettings?.tigerconnect_integrationid)
      ) {
        const payload = {
          id: "00000000-0000-0000-0000-000000000000",
          tigerconnect_2way_sync: rowData.two_way_sync,
          tigerconnect_integrationid: rowData.integrationid,
        }
        changes.push(
          api
            .updateGroupSettings(groupId, payload)
            .catch((error) => handleApiError(error))
        )
      } else if (
        idx !== 0 &&
        (rowData.integrationid || rowData.two_way_sync !== undefined)
      ) {
        const payload = {
          tigerconnect_settings: [
            {
              id: rowData.id,
              integrationid: rowData.integrationid,
              groupid: rowData.groupid,
              label: rowData.label,
              two_way_sync: rowData.two_way_sync,
              tigerconnect_key: rowData.tigerconnect_key,
              tigerconnect_secret: rowData.tigerconnect_secret,
              organization_token: rowData.organization_token,
              created_at: rowData.created_at,
              updated_at: new Date().toISOString(),
            },
          ],
        }
        changes.push(
          api
            .updateGroupSettings(groupId, payload)
            .catch((error) => handleApiError(error))
        )
      }
    })

    if (changes.length > 0) {
      try {
        await Promise.all(changes)
        await refreshClinicData()
        setShowDetailModal(false)
        setSelectedGroup(null)
      } catch (error) {
        handleApiError(error as PlainObjectType)
      }
    }

    setIsSaving(false)
    setEditingRowData({})
  }

  const handleEditRow = (index: number) => {
    setIsEditingRow(true)
    setEditingRowIndex(index)

    setEditingRowData((prev) => {
      if (prev[index]) return prev

      const tigerConnectSettings = groupSettings?.tigerconnect_settings || []
      const initialData: TigerConnectSetting = {
        id: index === 0 ? "" : tigerConnectSettings[index - 1]?.id || "",
        groupid: selectedGroup?.groupid || 0,
        label:
          index === 0
            ? "Default Label"
            : tigerConnectSettings[index - 1]?.label || "",
        integrationid:
          index === 0
            ? String(groupSettings?.tigerconnect_integrationid || "")
            : String(tigerConnectSettings[index - 1]?.integrationid || ""),
        two_way_sync:
          index === 0
            ? ["true", "1"].includes(
                groupSettings?.tigerconnect_2way_sync?.toString() || ""
              )
            : tigerConnectSettings[index - 1]?.two_way_sync || false,
        tigerconnect_key:
          index === 0
            ? typeof groupSettings?.tigerconnect_key === "string"
              ? groupSettings?.tigerconnect_key
              : ""
            : tigerConnectSettings[index - 1]?.tigerconnect_key || "",
        tigerconnect_secret:
          index === 0
            ? typeof groupSettings?.tigerconnect_secret === "string"
              ? groupSettings?.tigerconnect_secret
              : ""
            : tigerConnectSettings[index - 1]?.tigerconnect_secret || "",
        organization_token:
          index === 0
            ? typeof groupSettings?.tigerconnect_organization === "string"
              ? groupSettings?.tigerconnect_organization
              : ""
            : tigerConnectSettings[index - 1]?.organization_token || "",
        created_at:
          index === 0
            ? typeof groupSettings?.created_at === "string"
              ? groupSettings.created_at
              : new Date().toISOString()
            : tigerConnectSettings[index - 1]?.created_at ||
              new Date().toISOString(),
        updated_at: new Date().toISOString(),
      }
      return {
        ...prev,
        [index]: initialData,
      }
    })
  }

  const modalTitle = (
    <div className={css.modalTitle}>
      <h4>{selectedGroup ? selectedGroup.name : "Group Detail"}</h4>
      <span>{selectedGroup ? selectedGroup.groupid : ""}</span>
    </div>
  )

  const settingsKeys = Object.keys(settingsMap) as (keyof typeof settingsMap)[]
  const settingsColumns = [
    settingsKeys.slice(0, Math.ceil(settingsKeys.length / 2)),
    settingsKeys.slice(Math.ceil(settingsKeys.length / 2)),
  ]

  return (
    <div className={css.tableContainer}>
      <div className={css.controls}>
        <input
          type="text"
          placeholder="Search by group name or ID"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className={css.searchInput}
        />
      </div>
      <TableVirtuoso
        ref={virtuosoRef}
        style={{ height: 600, width: "100%" }}
        data={pagedData}
        fixedHeaderContent={() => (
          <GroupManagementHeader
            sortBy={sortBy}
            sortOrder={sortOrder}
            handleSortChange={handleSortChange}
          />
        )}
        itemContent={(index, group) => (
          <tr key={index} className={css.groupManagementTable}>
            <td
              className={`${css.selectGroup}`}
              onClick={() => openGroupDetailModal(group)}
            >
              {group.groupid}
            </td>
            <td
              className={`${css.groupContainer} ${css.selectGroup}`}
              onClick={() => openGroupDetailModal(group)}
            >
              {group.name.length > 25 ? (
                <Tooltip title={group.name}>
                  <span>{group.name}</span>
                </Tooltip>
              ) : (
                <span>{group.name}</span>
              )}
            </td>
            <td className={css.groupContainer}>{group.abbrev}</td>
            <td>{group.jobs_count}</td>
            <td>{group.tc_integrated_jobs_count}</td>
            <td>{group.providers_count}</td>
            <td>{group.active_providers_count}</td>
            <td>{group.schedulers_count}</td>
            <td>{group.active_schedulers_count}</td>
            <td>{group.upcoming_assignments_count}</td>
            <td className={css.dateColumn}>{group.latest_assigned_date}</td>
            <td className={css.dateColumn}>
              {group.latest_assigned_date_draft}
            </td>
            <td>{group.provider_protocols_count}</td>
            <td>{group.protocols_count}</td>
            <td>{group.tigerconnect_roles_sync ? "Yes" : "No"}</td>
            <td>
              {group.scheduling_gap_notifications ? "Enabled" : "Disabled"}
            </td>
            <td>{formatTimezone(group)}</td>
          </tr>
        )}
      />
      <div className={css.paginationControls}>
        <button
          disabled={currentPage === 1}
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
        >
          Previous
        </button>
        <div className={css.paginationInfo}>
          <div>
            {startIndex}–{endIndex} of {totalItems}
          </div>
          <div>Page {currentPage}</div>
        </div>
        <button
          disabled={currentPage * PAGE_SIZE >= filteredData.length}
          onClick={() => setCurrentPage((prev) => prev + 1)}
        >
          Next
        </button>
      </div>
      <Modal
        title={modalTitle}
        show={showDetailModal}
        className={css.modal}
        size="lg"
        onHide={() => {
          setShowDetailModal(false)
          setSelectedGroup(null)
        }}
      >
        <div className={css.modalContent}>
          <div className={css.groupContainer}>
            <h3 className={css.label}>Group Name</h3>
            <input
              className={css.groupNameInput}
              type="text"
              value={editedGroupName || ""}
              onChange={(e) => setEditedGroupName(e.target.value)}
              readOnly
              disabled
            />
          </div>
          <div className={css.groupContainer}>
            <h3 className={css.label}>Settings</h3>
            <div className={css.groupSettingsCheckboxContainer}>
              {settingsColumns.map((column, colIndex) => (
                <div key={colIndex} className={css.groupSettingsColumn}>
                  {column.map((key: keyof typeof settingsMap) => {
                    const inputId = `group-setting-${key}`
                    return (
                      <div key={key} className={css.groupSettingsCheckboxItem}>
                        <input
                          type="checkbox"
                          id={inputId}
                          checked={groupSettings?.[key] || false}
                          readOnly
                        />
                        <label htmlFor={inputId}>{settingsMap[key]}</label>
                      </div>
                    )
                  })}
                </div>
              ))}
            </div>
          </div>
          <div className={css.groupContainer}>
            <h3 className={css.label}>TigerConnect Settings</h3>
            <table className={css.groupSettingsTable}>
              <thead>
                <tr className={css.groupSettingsTable}>
                  <th className={css.tokenColumn}>Label</th>
                  <th className={css.tokenColumn}>Org Token</th>
                  <th className={css.tokenColumn}>API Key</th>
                  <th className={css.tokenColumn}>Integration ID</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {settingsTableData.map((data, index) => {
                  const isEditing = editingRowIndex === index
                  const rowData = editingRowData[index] || {}
                  const [orgToken, apiKey, intId] = data.value
                    .split(",")
                    .map((item) => item.split(":")[1]?.trim() || "N/A")

                  return (
                    <Fragment>
                      <tr key={index} className={css.groupSettingsTable}>
                        <td className={css.tokenColumn}>
                          {data.label.length > 25 ? (
                            <Tooltip title={data.label}>
                              <span>{data.label}</span>
                            </Tooltip>
                          ) : (
                            <span>{data.label}</span>
                          )}
                        </td>
                        <td className={css.tokenColumn}>
                          <Tooltip title={orgToken}>
                            <span>{orgToken}</span>
                          </Tooltip>
                        </td>
                        <td className={css.tokenColumn}>
                          <Tooltip title={apiKey}>
                            <span>{apiKey}</span>
                          </Tooltip>
                        </td>
                        <td className={css.tokenColumn}>
                          {" "}
                          <Tooltip title={intId}>
                            <span>{intId}</span>
                          </Tooltip>
                        </td>
                        <td>
                          <button
                            onClick={() => {
                              if (isEditing) {
                                setEditingRowIndex(null)
                                setIsEditingRow(false)
                              } else {
                                handleEditRow(index)
                              }
                            }}
                            className={`${css.editSettings} ${css.hiddenElement}`}
                          >
                            Edit
                          </button>
                        </td>
                      </tr>
                      {isEditing && (
                        <tr className={css.editRow}>
                          <td colSpan={5}>
                            <div className={css.groupContainer}>
                              <div>
                                <h3>Integration ID:</h3>
                                <input
                                  type="text"
                                  value={rowData?.integrationid || ""}
                                  onChange={(e) => {
                                    setEditingRowData((prev) => ({
                                      ...prev,
                                      [index]: {
                                        ...prev[index],
                                        integrationid: e.target.value,
                                      },
                                    }))
                                  }}
                                />
                              </div>
                              <div
                                className={`${css.groupContainer} ${css.twoWaySyncContainer}`}
                              >
                                <h3>2-Way Sync Enabled:</h3>
                                <input
                                  type="checkbox"
                                  checked={rowData?.two_way_sync || false}
                                  onChange={(e) => {
                                    setEditingRowData((prev) => ({
                                      ...prev,
                                      [index]: {
                                        ...prev[index],
                                        two_way_sync: e.target.checked,
                                      },
                                    }))
                                  }}
                                />
                              </div>
                              <div className={css.buttonContainer}>
                                <button
                                  className={css.cancelSettings}
                                  onClick={() => {
                                    setEditingRowIndex(null)
                                    setIsEditingRow(false)
                                    setEditingRowData((prev) => {
                                      const updatedData = { ...prev }
                                      delete updatedData[index]
                                      return updatedData
                                    })
                                  }}
                                >
                                  Cancel
                                </button>
                                <button
                                  className={css.saveSettings}
                                  onClick={() => {
                                    setEditingRowIndex(null)
                                    setIsEditingRow(false)
                                  }}
                                >
                                  Save
                                </button>
                              </div>
                            </div>
                          </td>
                        </tr>
                      )}
                    </Fragment>
                  )
                })}
              </tbody>
            </table>
          </div>
          <div className={css.buttonContainer}>
            <button
              className={css.cancelSettings}
              onClick={() => setShowDetailModal(false)}
              disabled={isSaving || isEditingRow}
            >
              Close
            </button>
            <button
              className={`${css.saveSettings} ${css.noDisplay}`}
              onClick={handleSave}
              disabled={isSaving || isEditingRow}
            >
              {isSaving ? "Saving..." : "Save"}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default GroupManagementTable
